import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import "./index.css";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);


const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector("div");
  
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(0, 0, 0, 0.7)";
      tooltipEl.style.borderRadius = "3px";
      tooltipEl.style.color = "white";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";
  
      const table = document.createElement("table");
      table.style.margin = "0px";
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  };
  const barLabels = [
    "0-5 SOL",
    "6-10 SOL",
    "11-20 SOL",
    "21-30 SOL",
    "31-40 SOL",
    "41-50 SOL",
    "51-60 SOL",
    "61-70 SOL",
    "71-80 SOL",
    "81-90 SOL",
    "100+ SOL",
  ];


const FloorChart = ({floors}) => {
  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    if (tooltip.body) {
      
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
  
      const tableHead = document.createElement("thead");
  
      titleLines.forEach((title) => {
        const tr = document.createElement("tr");
        tr.style.borderWidth = 0;
  
        const th = document.createElement("th");
        th.style.borderWidth = 0;
        tr.style.fontSize = "14px";
        const text = document.createTextNode(`Floor price: ${title}`);
  
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement("tbody");
  
      // Calculating total listed count
      const flattenedArray = bodyLines.flat(Infinity);
      const totalListedCount = flattenedArray
        .map((item) => parseInt(item.replace(/\D/g, "")))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      const totalListed = document.createElement("span");
      totalListed.style.fontSize = "14px";
      totalListed.innerText = `Total listed: ${totalListedCount}`;
  
      tableBody.appendChild(totalListed);
  
      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
  
        const span = document.createElement("span");
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = "2px";
        span.style.marginRight = "4px";
        span.style.height = "10px";
        span.style.width = "10px";
        span.style.display = "inline-block";
  
        const tr = document.createElement("tr");
        tr.style.backgroundColor = "inherit";
        tr.style.borderWidth = 0;
        tr.style.fontSize = "14px";
  
        const td = document.createElement("td");
        td.style.borderWidth = 0;
  
        const text = document.createTextNode(body);
  
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      // Calculating total Volume N multiplies by floor price
      const totalVolume = document.createElement("span");
      totalVolume.style.fontSize = "14px";
      
      totalVolume.innerText = `Total Volume:  SOL`;
  
      tableBody.appendChild(totalVolume);
  
      const tableRoot = tooltipEl.querySelector("table");
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
  };
  
  const barOptions = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return value;
          },
        },
      },
      x: {
        beginAtZero: true,
      },
    },
  };
  
  const barData = {
    labels: barLabels,
    datasets: [
      {
        label: "Other Hand Wallet",
        data: floors["OTHERHAND_COUNTS"],
        backgroundColor: "rgb(255, 99, 132)",
        stack: "Stack 0",
      },
      {
        label: "Whitelisted Wallets",
        data: floors["WHITELISTED_COUNTS"],
        backgroundColor: "rgb(75, 192, 192)",
        stack: "Stack 0",
      },
    ],
  };
  return (
    <Bar options={barOptions} data={barData} />
  )
};


export default FloorChart