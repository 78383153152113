import { useState, useCallback } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";

import "./index.css";



const Login = ({auth, setUserData}) => {
  const [errorStatus, setErrorStatus] = useState(0);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
 
  const handleInputChange = (e) => {
    const target = e.currentTarget;

    if (target.type === "email") {
      setLoginData((prev) => ({ ...prev, [target.type]: target.value }));
    }

    if (target.type === "password") {
      setLoginData((prev) => ({ ...prev, [target.type]: target.value }));
    }
  };

  const handleButtonSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const processSign = await signInWithEmailAndPassword(
          auth,
          loginData.email,
          loginData.password
        );
        const {email, uid} = processSign.user;

        setUserData({
          email,
          uid,
          logStatus: 200
        })
        setErrorStatus(200)
        
      } catch (error) {
        console.log(error);
        setErrorStatus(400)
      }
    },
    [loginData, auth]
  );
    console.log(errorStatus);
  return (
    <div className="container h-100">
      <div className="row h-100 align-items-center">
        <form className="login--container" onSubmit={handleButtonSubmit}>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={handleInputChange}
            />
            <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              onChange={handleInputChange}
            />
          </div>
          {errorStatus === 400 && (
            <div id="emailHelp" className="form-text text-danger mb-3">
              User not found
            </div>
          )}
          {errorStatus === 200 && (
            <div id="emailHelp" className="form-text text-success">
              Logged In
            </div>
          )}
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
