import FloorChart from "./FloorChart";
import WalletOwnCount from "./WalletOwnCount";
import ProfitPie from "./ProfitPie";
import "./index.css";

const Dashboard = ({ userData }) => {
   if(!userData) return null;
  const data = {
    "TOTAL_BALANCE": "250.14 SOL",
    "TOKEN_OWNERS": [
        {
            "COUNT": 418,
            "CATHEGORY": "WHITELISTED"
        }, 
        {
            "COUNT": 122,
            "CATHEGORY": "OTHERHAND"
        }
    ],
    "FLOOR_CHART": {
        "WHITELISTED_COUNTS": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        "OTHERHAND_COUNTS": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        "TOTAL_VOLUMES": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        "SWEEP_COST": "123.4 SOL"
    },
    "PROFIT_CHART": {
        "SOLD": {
            "TOTAL_SOL": 1000.0,
            "COUNT": 58
        },
        "LEFT": {
            "PREDICTABLE_SOL": 5000.0,
            "COUNT": 597
        }
    }
}
  return (
    <>
      <header className="dashboard--header shadow mb-5 px-4">
       <h2 className="mb-0">{userData.email}</h2>
      </header>
      <div className="container-fluid">
        <h3 className="text-end">Wallets Total Balance: {data["TOTAL_BALANCE"]}</h3>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 chart-container">
              <WalletOwnCount owners={data["TOKEN_OWNERS"]} />
            </div>
            <div className="col-lg-6 col-12 chart-container">
              <FloorChart floors={data["FLOOR_CHART"]} />
            </div>
            <div className="col-lg-6 col-12 chart-container mt-5">
                <ProfitPie profits={data["PROFIT_CHART"]} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
