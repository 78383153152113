import {useState, useEffect} from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import Login from './Login';
import Dashboard from './Dashboard';
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyAyahy0ZrUY81U6aM0h_AiOIglfVJks9ws",
  authDomain: "d-dash-4abdc.firebaseapp.com",
  projectId: "d-dash-4abdc",
  storageBucket: "d-dash-4abdc.appspot.com",
  // appId: "APP_ID",
};

function App() {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const [userData, setUserData] = useState({
    email: '',
    uid: '',
    logStatus: 0
  });

  const logOut = () => {signOut(auth)}

  useEffect(() => {
      window.addEventListener('beforeunload', logOut);

      return () => {
        window.removeEventListener('beforeunload', logOut);
      }
  }, [])


  return (
    <div className="app">
      {userData.logStatus === 0 ? (<Login auth={auth} setUserData={setUserData} />) : (<Dashboard userData={userData} />) }
      
    </div>
  );
}

export default App;
