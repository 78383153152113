import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const WalletOwnCount = ({owners}) => {
  const arrayOfNumbers = owners.reduce((prev, curr) => [prev.COUNT, curr.COUNT])
  
  const data = {
    labels: ["Whitelisted Wallets", "Other Hand Wallets"],
    datasets: [
      {
        label: "# of Votes",
        data: arrayOfNumbers,
        backgroundColor: ["rgba(54, 162, 235, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      }
    ],
  };

  return (
    <Pie
      data={data}
      updateMode="active"
      height={600}
      options={{ maintainAspectRatio: false }}
    />
  )
};

export default WalletOwnCount;
