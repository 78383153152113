import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const ProfitPie = ({profits}) => {
  const data = {
    labels: ["Profit in SOL", "Potential profit"],
    datasets: [
      {
        label: "# of Votes",
        data: [profits.SOLD.COUNT, profits.LEFT.COUNT],
        backgroundColor: ["rgba(255, 193, 7, 1)", "rgba(13, 202, 240, 1)"],
        borderColor: ["rgba(255, 255, 255, 1)", "rgba(13, 202, 240, 1)"],
        borderWidth: 1,
      },
    ],
  };
  
  const footer = (tooltipItems) => {
      let sum = 0;
      // TO DO 
      const data = tooltipItems[0].label === 'Potential profit' ? `${profits.LEFT.COUNT} left / Approximate profit: ${profits.LEFT.PREDICTABLE_SOL}` : `${profits.SOLD.COUNT} sold, profit: ${profits.SOLD.TOTAL_SOL}`;
      tooltipItems.forEach(function(tooltipItem) {
        sum += tooltipItem.parsed;
      });
      
      return (`${sum} SOL, ${data}`);
  };


  return (
    <Pie
      data={data}
      height={600}
      options={{
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              footer: footer,
            }
          }
        }
      
      }}
    />
  )
};

export default ProfitPie;
